<template>
  <div v-if="loading" class="wrapper-lg">
    <sl-loading content="" />
  </div>
  <div class="cliente-fatura" v-else>
    <div class="f-left">
      <div class="fatura-nav">
        <router-link :to="{name: 'suporte.cliente.faturas'}">Voltar para faturas</router-link>
      </div>
      <div class="print-view-folha-a4 box-v2 no-border-radius" style="padding: 30px; min-height: auto">
        <h2 class="component-title text-center m-b">Contrato de Serviço</h2>
        <div>Contrato de serviço <strong>ID {{contrato.cliente.id}}{{ String(`${contrato.id}`).padStart(8, '0') }}</strong> firmado em {{contrato.createdAt|formatDate}} com a finalidade de uso do produto/serviço <strong>{{contrato.produto.nome}}</strong></div>
        <e-row class="m-t-lg" mr>
          <e-col>
            <erp-s-field label="Status:">
              <div v-if="contrato.status === 0" class="text-grey">Pendente</div>
              <div v-if="contrato.status === 1" class="text-green">Ativo</div>
              <div v-if="contrato.status === 98">Expirado</div>
              <div v-if="contrato.status === 99" class="text-negative">Cancelado</div>
              <div v-if="contrato.status === 100" class="text-grey">Encerrado</div>
              <div class="font-10 text-grey-5 capitalize">{{String(contrato.motivoStatus).toLowerCase()}}</div>
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Data de Início:">
              {{contrato.dataInicio|formatDate('dd/MM/yyyy')}}
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Valor total:">
              R${{contrato.valorContrato|moeda}}
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Data de Encerramento:">
              {{contrato.dataFim|formatDate('dd/MM/yyyy')}}
            </erp-s-field>
          </e-col>
        </e-row>
        <div v-if="assinaturas && assinaturas.length" class="m-t-lg">
          <div class="font-16">Assinaturas relacionadas à este contrato:</div>
          <div class="erp-table table-v2" style="min-height: auto">
            <table class="u-table m-t-sm">
              <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">Cobrança</th>
                <th class="text-left">Próximo Vencimento</th>
                <th>Valor</th>
                <th>Situação</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <template v-for="a in assinaturas">
                <tr :key="'a-' + a.id">
                  <td>{{a.id}}</td>
                  <td>
                    <div v-if="a.contrato?.produto?.criterioCobranca === 2">
                      De acordo uso
                    </div>
                    <div v-else>
                      Recorrente
                    </div>
                  </td>
                  <td>{{ a.dataProximoVencimento|formatDate('dd/MM/yyyy') }}</td>
                  <td class="text-center">
                    <div v-if="contrato?.produto?.criterioCobranca === 2">
                      R$ {{ contrato?.produto?.valor|moeda2 }}
                      <div class="font-11 text-grey" v-if="contrato?.produto?.unidadeMedidaCobrancaDescricao">
                        {{ contrato?.produto?.unidadeMedidaCobrancaDescricao }}
                      </div>
                    </div>
                    <div v-else>
                      R${{ a.valor|moeda }}
                      <div class="font-11 text-grey">{{ periodicidadeString(a.periodicidade) }}</div>
                    </div>
                  </td>
                  <td class="text-center">
                    <div v-if="atraso(a.dataProximoVencimento) > 0" class="text-negative">
                      {{ atraso(a.dataProximoVencimento) }} dias em atraso
                    </div>
                    <div v-else-if="contrato?.status === 0" class="text-grey">Pendente</div>
                    <div v-if="contrato?.status === 1" class="text-green">Ativo</div>
                    <div v-if="contrato?.status === 98">Expirado</div>
                    <div v-if="contrato?.status === 99" class="text-negative">Cancelado</div>
                    <div v-if="contrato?.status === 100" class="text-grey">Encerrado</div>
                  </td>
                  <td>
                    <a @click="a._detalhes = !a._detalhes" class="non-selectable">Detalhes</a>
                  </td>
                </tr>
                <tr v-if="a._detalhes" :key="'ab-' + a.id" class="no-hover">
                  <td colspan="100%">
                    <e-row class="m-t-lg" mr>
                      <e-col>
                        <erp-s-field label="Início:">
                          {{ a.dataInicio|formatDate('dd/MM/yyyy') }}
                        </erp-s-field>
                      </e-col>
                      <e-col>
                        <erp-s-field label="Término:">
                          {{ a.dataTermino|formatDate('dd/MM/yyyy') }}
                        </erp-s-field>
                      </e-col>
                      <e-col>
                        <erp-s-field label="Último Pagamento:">
                          {{ a.dataUltimoPagamento|formatDate('dd/MM/yyyy') }}
                        </erp-s-field>
                      </e-col>
                      <e-col>
                        <erp-s-field label="Próximo Pagamento:">
                          {{ a.dataProximoVencimento|formatDate('dd/MM/yyyy') }}
                        </erp-s-field>
                      </e-col>
                      <e-col>
                        <erp-s-field label="Status">
                          {{ a.dataProximoVencimento|formatDate('dd/MM/yyyy') }}
                        </erp-s-field>
                      </e-col>
                    </e-row>
                    <!-- <a v-if="a.active" @click="cancelarAssinatura(a.id)" class="text-negative">Cancelar</a> -->
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="movimentacoes && movimentacoes.length" class="m-t-lg">
          <div class="font-16">Faturas relacionadas à este contrato:</div>
          <div class="erp-table table-v2" style="min-height: auto">
            <table class="u-table m-t-sm">
              <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">Data</th>
                <th class="text-left">Descrição</th>
                <th>Valor</th>
                <th>Situação</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <template v-for="m in movimentacoes">
                <tr :key="'m-' + m.id">
                  <td>{{m.id}}</td>
                  <td>{{m.dataVencimento|formatDate('dd/MM/yyyy')}}</td>
                  <td>
                    {{m.descricao}}
                  </td>
                  <td class="text-center">{{ m.valor|moeda }}</td>
                  <td class="text-center">
                    <div v-if="atraso(m.dataVencimento) > 0" class="text-negative">
                      {{ atraso(m.dataVencimento) }} dias em atraso
                    </div>
                    <div v-else-if="m.pago">Pago</div>
                    <div v-else>Aberto</div>
                  </td>
                  <td>
                    <router-link v-if="!m.pago" :to="{name: 'suporte.cliente.faturas.pagarFatura', params: {id: m.id}}" class="text-primary">Pagar</router-link>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="fatura-opts">
        <a @click="downloadContrato">
          <span>Baixar Contrato Digital</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import {contrato, downloadContrato} from "@/domain/business/services"
import SlLoading from "components/layout/components/Loading.vue"
import ERow from "components/layout/components/Row.vue"
import ECol from "components/layout/components/Col.vue"
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField"
import {donwloadFile} from "@/utils/downloadFile"
import AssinaturaHelperMixin from "components/suporte/cliente/components/include/assinaturaHelperMixin"

export default {
  name: "ClienteContrato",
  mixins: [AssinaturaHelperMixin],
  data () {
    return {
      loading: true,
      contrato: null,
      assinaturas: null,
      movimentacoes: null
    }
  },
  computed: {
  },
  components: {
    ErpSField,
    ECol,
    ERow,
    SlLoading
  },
  mounted() {
    this.load(this.$route.params.id)
  },
  methods: {
    load (id = null) {
      if (!id) {
        id = this.$route.params.id
      }
      this.loading = true
      contrato(id)
          .then(response => {
            this.contrato = response.data.contrato
            this.assinaturas = response.data.assinaturas && response.data.assinaturas.length ? response.data.assinaturas.map(a => {
              a._detalhes = false
              return a
                }) : []
            this.movimentacoes = response.data.movimentacoes && response.data.movimentacoes.length ? response.data.movimentacoes.map(m => {
              m._detalhes = false
              return m
                }) : []
            this.$nextTick(() => {
              this.loading = false
            })
          })
          .catch(error => {
            this.alertApiError(error)
            // this.loading = false
          })
    },
    downloadContrato () {
      downloadContrato(this.contrato.id)
          .then((response) => {
            donwloadFile(response)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    }
  }
}
</script>
