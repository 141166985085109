import http from "@/utils/services/http"
import {download} from "@/domain/leiloes/services/financeiro";

export const invoices = () => {
    let url = '/api/business/invoices'
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const invoice = (id) => {
    let url = `/api/business/invoices/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
export const downloadInvoice = (id) => {
    let url = `/api/business/invoices/${id}/download`
    return download(url)
}

export const payInvoice = (id, gateway, method, extra = null) => {
    let url = `/api/business/invoices/${id}/pay`
    let config = {}
    config.timeout = 30000
    return http.post(url, {
        gateway: gateway,
        method: method,
        extra: extra
    }, config)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const checkStatusInvoice = (id) => {
    let url = `/api/business/invoices/${id}/status`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const cadastraCartao = (dadosCartao) => {
    let url = `/api/business/invoices/salvaCartao`
    return http.post(url, dadosCartao)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
export const excluirMetodoPagamento = (id) => {
    let url = `/api/business/invoices/metodosPagamento/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const contrato = (id) => {
    let url = `/api/business/contratos/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const downloadContrato = (id) => {
    let url = `/api/business/contratos/${id}/download`
    return download(url)
}
